import route from '../router';
import axios from "axios";

   //var  baseURL = "http://localhost:8000/api";
   
   var baseURL = "https://datamap.app.br/api";
   
   var headers = {
        'Accept' : 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
   };


   if( localStorage.getItem("currentToken") != undefined){ 
         headers['Authorization']  = "Bearer "+localStorage.getItem("currentToken");
   }

  const Api = axios.create({
      baseURL: baseURL,
      headers: headers,
      router : route
  });

export default Api;
